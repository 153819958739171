.menu_profile_items{
  transition: all .1s ease;
  &:hover{
    background-color: #CAD3EC;
    .menu_profile_title{
      color: #2C50B5 !important;
    }
    .menu_profile_desc{
      color: #2C50B5;
    }
  }
}
.customFade {
  animation: fadeInAnimation ease-in-out .3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}