.wizard {
  width: 100%;

  & .wizard-steps-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;

    & .wizard-steps {
      width: inherit;
      text-align: center;
      font-weight: 600;
      font-size: 0.8rem;

      &.completed {
        & .wizard-steps-dot {
          background: $primary;
        }
        & .wizard-steps-line {
          background-color: $primary;
        }
      }

      &.active {
        & .wizard-steps-dot {
          background: $primary;
        }
        & .wizard-steps-line.first {
          background-color: $primary;
        }
      }

      & .wizard-steps-line {
        background-color: $gray-500;
        height: 4px;
        width: 48%;
        position: relative;
        // left: -47%;
        z-index: 1;
        top: -29px;
        &.first{
          float: left;
        }
        &.second{
          float: right;
        }
      }

      & .wizard-steps-dot {
        width: 27px;
        z-index: 9;
        margin: auto;
        height: 27px;
        color: white;
        padding: 4.5px;
        // cursor: pointer;
        font-size: 0.8rem;
        font-weight: bold;
        position: relative;
        margin-bottom: 14px;
        border-radius: 100%;
        background: $gray-500;
      }
    }
  }
}
