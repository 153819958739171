.form-select-control.is-invalid > div.select2-selection__control {
  border-color: $red !important;
  padding-right: calc(1.5em + 0.94rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.235rem) center;
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.form-select-control > div.select2-selection__control--is-disabled,
.select2-selection--is-disabled > .select2-selection__control--is-disabled {
  background-color: #eff2f7 !important;
  opacity: 1;
}

.input-group > .form-select-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.float-right {
  float: right;
}

.mr-2-custom {
  margin-right: 1rem;
}

.mb-2-custom {
  margin-bottom: 1rem;
}

.aspect-ratio-1 {
  aspect-ratio: 1;
}

.bg-header-auth {
  background-color: #e0e6f5;

  & h4,
  span,
  h5 {
    color: #1c326e !important;
  }
}

body[data-layout="horizontal"][data-topbar="light"] .topnav,
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #1c326e;
}

.berkas-wrapper {
  max-height: 250px;
  overflow-y: auto;
}

.topnav .navbar-nav .nav-link {
  color: gainsboro;
}
.topnav .navbar-nav .dropdown.active > a {
  color: gainsboro;
  &.active {
    color: white;
    font-weight: 600;
  }
  &:hover {
    color: white;
    font-weight: 600;
  }
}

.text-header {
  color: #1c326e !important;
}
.overflow-x {
  overflow-x: auto;
}

.invalid-feedback-options {
  margin-top: 0.25rem;
  color: $danger;
  font-size: 80%;
}

//react data table rows FIX ISSUE BORDERED RESPONSIVE TABLE
.rdt_TableRow:not(:last-of-type) {
  border-bottom-style: none !important;
  // border-bottom-width: 1px;
  // border-bottom-color: rgba(0,0,0,.12);
}
.rdt_TableCell {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.rdt_TableHeadRow {
  border-bottom-style: none !important;
  .rdt_TableCol {
    background-color: #fafafa !important;
  }
}
.rdt_TableCol {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.rdt_TableCell {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
//END OF react data table rows FIX ISSUE BORDERED RESPONSIVE TABLE

/* Styles for the custom scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Making track background transparent */
  border-radius: 8px; /* More rounded track border radius */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.5); /* Semi-transparent thumb color */
  border-radius: 8px; /* More rounded thumb border radius */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.5); /* Semi-transparent thumb color on hover */
}

/* Firefox scrollbar styles */
.custom-scrollbar {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: rgba(136, 136, 136, 0.5) transparent; /* Semi-transparent thumb and transparent track color */
}

.custom-form-check {
	display: flex;
	column-gap: 16px;
}

.custom-form-check input[type="checkbox"] {
	width: 1.3rem;
	height: 1.3rem;
}